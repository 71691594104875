.suggestions
{
    margin-top: 80px;
    margin-bottom: 120px;
}

.suggestions h2
{
    margin-top: 80px;
    margin-bottom: 40px;
}

.suggestions-row
{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media(min-width: 750px)
{
    .suggestions-row
    {
        flex-direction: row;
    }
}

.suggestions-card
{
    width: 100%;
}

.suggestions-card a
{
    width: 100%;
}

.suggestions-image-container
{
    position: relative;
    height: 160px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: #ebecf0;
    border-radius: 12px;
    transition: 0.5s ease all;
    cursor: pointer;
}

@media(min-width: 480px)
{
    .suggestions-image-container
    {
        height: 220px;
    }
}

@media(min-width: 750px)
{
    .suggestions-image-container
    {
        height: 260px;
    }
}

.suggestions-image-container::after
{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0;
    transition: 0.5s ease all;
}
  
.suggestions-image-container:hover::after
{
    opacity: 0.1;
}

.suggestions-image-container:hover img
{
    filter: blur(30px);
}

.suggestions-image-container:hover .suggestions-text-container
{
    opacity: 1;
}

.suggestions-image-container img
{
    position: relative;
    object-fit: cover;
    transition: 0.5s ease all;
    width: 250px;
    height: 250px;
}

@media(min-width: 480px)
{
    .suggestions-image-container img
    {
        width: 325px;
        height: 325px;
    }
}

@media(min-width: 750px)
{
    .suggestions-image-container img
    {
        width: 300px;
        height: 300px;
    }
}

.suggestions-card #customuse img
{
    top: 40px;
}

@media(min-width: 480px)
{
    .suggestions-card #customuse img
    {
        top: 45px;
    }
}

@media(min-width: 750px)
{
    .suggestions-card #customuse img
    {
        top: 50px;
    }
}

.suggestions-text-container
{
    position: absolute;
    display: flex;
    height: auto;
    width: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 1;
    color: white;
    pointer-events: none;
    opacity: 0;
    transition: 0.5s ease all;
}

.suggestions-text-container h3
{
    font-size: 20px;
    margin-bottom: 10px;
}

.suggestions-text-container p
{
    margin: 0;
}

.suggestions-text-container span
{
    font-size: 12px;
    background-color: #ffffff30;
    padding: 5px 16px;
    border-radius: 20px;
    margin: 0 5px;
    box-shadow: 0 0 10px #00000010;
}