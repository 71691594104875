*
{
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

:root
{
    --bg-white: #f8f8f8;
    --bg-light-grey: #eeeeee;
    --bg-light-blue: #ebedf0;
    --light-grey: #bbbbbb;
    --mid-grey: #888888;
    --dark-grey: #555555;
    --main-dark: #333333;
    --highlight: #5aa2e1;
}

html, body
{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--bg-white);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    color: var(--main-dark);
    line-height: 1.8em;
    font-family: 'Montserrat', sans-serif;
}

@media(max-width: 750px)
{
    body
    {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    
    body::-webkit-scrollbar
    {
        display: none;
    }
}

.container
{
    max-width: 1000px;
    padding: 20px;
    margin: auto;
}

@media(min-width: 480px)
{
    .container
    {
        padding: 40px;
    }
}

@media(min-width: 750px)
{
    .container
    {
        padding: 80px;
    }
}

h1
{
    font-size: 60px;
    line-height: 60px;
    color: var(--dark-grey);
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 700;
    text-align: left;
}

@media(min-width: 480px)
{
    h1
    {
        font-size: 70px;
        line-height: 70px;
    }
}

@media(min-width: 750px)
{
    h1
    {
        font-size: 80px;
        line-height: 80px;
    }
}

h2
{
    font-size: 20px;
    font-weight: 600;
    color: var(--dark-grey);
    margin-bottom: 20px;
}

h3
{
    font-size: 16px;
    font-weight: 600;
}

p
{
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 28px;
}

.link
{
    color: var(--highlight);
    text-decoration: none;
    font-weight: 500;
    padding: 2px 0;
    position: relative;
    cursor: pointer;
}

.link:hover
{
    opacity: 0.8;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.image-container
{
    margin-bottom: 60px;
}

.image-container img
{
    width: 100%;
}

.image-container.multi
{
    display: flex;
    justify-content: space-between;
}

.image-container.multi img
{
    width: 50%;
}

.image-container p
{
    font-size: 14px;
    line-height: 20px;
    color: var(--mid-grey);
    text-align: center;
}

.image-container a
{
    margin-left: 10px;
}

.image-container.video p
{
    margin: 12px 0 20px 0;
}

.text-container
{
    margin-bottom: 60px;
}

iframe
{
    width: 100%;
    aspect-ratio: 16 / 9;
    margin-bottom: 60px;
}