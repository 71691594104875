.footer
{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
}

/* .footer:before
{
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX( -50%);
    height: 100%;
    width: 100vw;
    z-index: -1;
} */

.footer h3
{
    color: var(--mid-grey);
    font-size: 14px;
    font-weight: 500;
    margin-top: 20px;
    order: 1;
}

.footer div, .footer a
{
    display: inline-block;
    height: 40px;
}

.footer img
{
    width: 24px;
    height: 24px;
    padding: 8px;
    opacity: 1;
}

.footer img:hover
{
    opacity: 0.8;
}

@media(min-width: 750px)
{
    .footer
    {
        flex-direction: row;
    }

    .footer h3
    {
        order: 0;
        margin: 0;
    }
}