.litho #top-text
{
    margin-bottom: 0;
}

.litho #top-text p:last-of-type
{
    margin-bottom: 0;
}

.litho .webgl
{
    position: relative;
}

.point
{
    position: absolute;
    z-index: 1;
}

.point .point-number
{
    position: absolute;
    top: -20;
    left: -20;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #55555590;
    text-align: center;
    color: #fff;
    line-height: 30px;
    font-size: 14px;
    cursor: help;
    transform: scale(0, 0);
    transition: transform 0.2s;
}

.point.visible .point-number
{
    transform: scale(1, 1);
}

@media(min-width: 750px)
{
    .point:hover .point-text
    {
        opacity: 1;
    }
}

.point .point-text
{
    position: absolute;
    top: 50px;
    left: -100px;
    width: 200px;
    padding: 20px;
    background: #555555a0;
    text-align: center;
    color: #fff;
    font-size: 14px;
    line-height: 22px;
    border-radius: 10px;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
}

.point.text-above .point-text
{
    top: -100px;
}

@media(min-width: 480px)
{
    .point .point-number
    {
        width: 40px;
        height: 40px;
        line-height: 40px;

        font-size: 16px;
    }
}

.litho #triple-video iframe
{
    width: 30%;
    height: 60vw;
    max-height: 490px;
}

@media(min-width: 480px)
{
    .litho #triple-video iframe
    {
        height: 50vw;
    }
}

@media(min-width: 750px)
{
    .litho #triple-video iframe
    {
       height: 40vw;
    }
}

#featured-in
{
    text-align: center;
    margin: 100px 0 50px 0;
}

#press-container
{
    width: 100%;
    max-width: 500px;
    margin: 0 auto 100px auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 50px;
    justify-items: center;
    align-items: center;
}

@media(min-width: 750px)
{
    #press-container
    {
        margin-bottom: 150px;
    }
}

#press-container #wired
{
    width: 120px;
    padding: 20px 0;
}

#press-container #gdn
{
    width: 80px;
    padding: 10px;
}

#press-container #the-verge
{
    width: 100px;
    padding: 10px;
}

#press-container #tech-crunch
{
    width: 120px;
    padding: 10px;
}

@media(min-width: 480px)
{
    #press-container #wired
    {
        width: 150px;
    }

    #press-container #gdn
    {
        width: 90px;
    }

    #press-container #the-verge
    {
        width: 100px;
    }

    #press-container #tech-crunch
    {
        width: 130px;
    }
}

@media(min-width: 750px)
{
    #press-container
    {
        grid-template-columns: repeat(4, 1fr);
        max-width: none;
    }

    #press-container #wired
    {
        margin-right: 30px;
    }
}