#header
{
    z-index: 100;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
}

@media(min-width: 750px)
{
    #header
    {
        margin-bottom: 0px;
        justify-content: space-between;
    }
}

#home-button
{
    position: absolute;
    top: 0;
    left: 0;
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: var(--dark-grey);
    text-decoration: none;
}

@media(min-width: 750px)
{
    #home-button
    {
        position: relative
    }
}

nav
{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.4s ease-in-out;
}

nav.expanded
{
    max-height: 270px;
}

@media(min-width: 750px)
{
    nav
    {
        width: 240px;
        flex-direction: row;
        max-height: none;
    }
}

nav a
{
    position: relative;
    margin: 10px 0;
    color: var(--mid-grey);
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
}

nav a:nth-of-type(1)
{
    margin-top: 120px;
}

nav a.current
{
    color: var(--highlight);
}

@media(min-width: 750px)
{
    nav a
    {
        flex-direction: row;
        margin: 0;
    }

    nav a:nth-of-type(1)
    {
        margin-top: 0;
    }
}

@media(min-width: 750px)
{
    nav a::before
    {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: var(--highlight);
        transform: scaleX(0);
        opacity: 0;
        transition: all 0.15s ease-out;
    }

    nav a:hover::before
    {
        transform: scaleX(1);
        opacity: 1;
    }
}

#header button
{
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    cursor: pointer;
    outline: none;
    border: none;
    background: none;
}

@media(min-width: 750px)
{
    #header button
    {
        display: none;
    }
}

#header button span
{
    background: var(--dark-grey);
    transition: all 0.2s ease-in-out;
    width: 26px;
    height: 2px;
    margin-bottom: 6px;
    display: block;
}

#header button span:nth-child(3)
{
    margin-bottom: 0;
}

#header button.collapsed span:nth-child(1)
{
    transform: translateY(8px) rotate(45deg);
}

#header button.collapsed span:nth-child(2)
{
    transform: scaleX(0);
    opacity: 0;
}

#header button.collapsed span:nth-child(3)
{
    transform: translateY(-8px) rotate(-45deg);
}