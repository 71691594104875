#contact h1
{
    margin: 100px 0 40px 0;
    text-align: center;
}

#contact p
{
    text-align: center;
    margin: 0 auto 80px auto;
    max-width: 330px;
}

@media(min-width: 750px)
{
    #contact p
    {
        max-width: 380px;
    }
}

#contact form
{
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 50px auto 100px auto;
    background-color: #fdfdfd;
    box-shadow: 0 0 40px #00000015;
    border-radius: 10px;
    width: 100%;
    max-width: 600px;
    padding: 20px;
}

@media(min-width: 480px)
{
    #contact form
    {
        padding: 40px;
    }
}

@media(min-width: 750px)
{
    #contact form
    {
        margin: 100px auto 200px auto;
        padding: 60px;
    }
}

#contact form label
{
    font-size: 15px;
    font-weight: 400;
}

#contact form input
{
    background: none;
    border: none;
    border-bottom: 1px solid #c3c3c3;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    border-radius: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}

#contact form input:focus
{
    outline: none;
}

#contact form textarea
{
    background: none;
    border: none;
    border-bottom: 1px solid #c3c3c3;
    box-sizing: border-box;
    width: 100%;
    height: 140px;
    margin-bottom: 20px;
    border-radius: 0;
    resize: vertical;
    min-height: 40px;
    max-height: 500px;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 20px;
}

@media(min-width: 480px)
{
    #contact form textarea
    {
        margin-bottom: 40px;
    }
}
@media(min-width: 750px)
{
    #contact form textarea
    {
        margin-bottom: 60px;
    }
}

#contact form textarea:focus
{
    outline: none;
}

#contact form button
{
    width: 100%;
    max-width: 350px;
    height: 40px;
    margin: auto;
    border-radius: 100px;
    border: none;
    background-color: var(--highlight);
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: white;
    font-size: 15px;
    cursor: pointer;
    transition: 0.2s background-color;
}

#contact form button:hover
{
    background: #555
}

#contact form button.success
{
    content: 'Message sent!'
}

/* _______________________________________________ SUCCESS */

#success h1
{
    margin: 300px 0 50px 0;
    text-align: center;
}

#success h3
{
    text-align: center;
    max-width: 180px;
    margin: 0 auto 350px auto;
}

@media(min-width: 480px)
{
    #success h3
    {
        max-width: none;
    }
}