.project-content
{
    line-height: 0px;
    margin: 80px 0px;
}

.project-header
{
    margin: 80px 0px;
}

.project-header h1
{
    margin-bottom: 32px;
}

.project-header h2
{
    margin-bottom: 40px;
    color: var(--light-grey);
}

.skills-container
{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 80px;
}

.skills-container span
{
    font-size: 11px;
    font-weight: 600;
    color: var(--light-grey);
    background-color: #ffffff;
    box-shadow: 0 0 10px #00000005;
    padding: 0px 16px;
    margin-right: 8px;
    border-radius: 20px;
}

hr
{
    border: 0.5px solid #dddddd;
}

.project-content img
{
    width: 100%;
}

.project-content img:not(:last-child)
{
    margin-bottom: 60px;
}

.work-page .work-content
{
    margin-top: 80px;
    margin-bottom: 120px;
}

@media(min-width: 480px)
{
    .work-page .work-content
    {
        margin-top: 120px;
        margin-bottom: 160px;
    }
}

@media(min-width: 750px)
{
    .work-page .work-content
    {
        margin-top: 120px;
        margin-bottom: 200px;
    }
}

.project-card
{
    width: 100%;
}

.work-page .project-card
{
    margin: auto;
    padding-bottom: 8px;
}

.work-image-container
{
    position: relative;
    height: 160px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: #ebecf0;
    border-radius: 12px;
    transform: skewY(-5deg);
    transition: 0.5s ease all;
    cursor: pointer;
}

@media(min-width: 480px)
{
    .work-image-container
    {
        height: 220px;
    }
}

@media(min-width: 750px)
{
    .work-image-container
    {
        height: 260px;
    }
}

.work-image-container::after
{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0;
    transition: 0.5s ease all;
}
  
.work-image-container:hover::after
{
    opacity: 0.1;
}

.work-image-container:hover img
{
    filter: blur(30px);
}

.work-image-container:hover .work-text-container
{
    opacity: 1;
}

.work-image-container:hover
{
    height: 220px;
}

@media(min-width: 480px)
{
    .work-image-container:hover
    {
        height: 300px;
    }
}

@media(min-width: 750px)
{
    .work-page .work-image-container:hover
    {
        height: 360px;
    }
}

.work-image-container img
{
    position: relative;
    object-fit: cover;
    transition: 0.5s ease all;
    transform: skewY(5deg);
    width: 250px;
    height: 250px;
}

@media(min-width: 480px)
{
    .work-image-container img
    {
        width: 325px;
        height: 325px;
    }
}

@media(min-width: 750px)
{
    .work-image-container img
    {
        width: 400px;
        height: 400px;
    }
}

#customuse img
{
    top: 30px;
}

@media(min-width: 480px)
{
    #customuse img
    {
        top: 45px;
    }
}

@media(min-width: 750px)
{
    #customuse img
    {
        top: 70px;
    }
}

.work-text-container
{
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 1;
    color: white;
    pointer-events: none;
    opacity: 0;
    transition: 0.5s ease all;
    transform: skewY(5deg);
}

.work-text-container h3
{
    font-size: 20px;
    margin-bottom: 10px;
}

.work-text-container p
{
    margin: 0;
}

.work-text-container span
{
    font-size: 12px;
    background-color: #ffffff30;
    padding: 5px 16px;
    border-radius: 20px;
    margin: 0 5px;
    box-shadow: 0 0 10px #00000010;
}

