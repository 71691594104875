.model-viewer body
{
    background-color: #1a1a1a;
}

#model-viewer-header
{
    display: flex;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    background: none;
    border: none;
    z-index: 1;
}

#model-viewer-header img
{
    width: 25px;
    padding: 10px;
    z-index: 1;
    cursor: pointer;
    opacity: 0.8;
}

#model-viewer-header img:hover
{
    opacity: 1;
}

#model-viewer-header select
{
    color: #fff;
    background: none;
    border: none;
    outline: none;
    text-align: center;
    font-size: 15px;
    line-height: 15px;
    font-weight: 400;
    padding: 10px;
    border-radius: 5px;
    z-index: 1;
    cursor: pointer;
}

#model-viewer-header select:hover
{
    background-color: #88888820;
}

#settings-button
{
    transition: all 0.2s;
}

#settings-button.hidden
{
    transform: translateX(35px);
}

#envMapThumbs
{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
    right: -80px;
    z-index: 1;
    transition: all 0.3s;
}

#envMapThumbs.visible
{
    right: 20px;
}

#envMapThumbs img
{
    width: 50px;
}

#envMapThumbs img:nth-of-type(1)
{
    width: 25px;
}

.webgl
{
    cursor: grab;
}