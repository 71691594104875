#top-heading
{
    text-align: center;
    margin-bottom: 40px;
    display: none;
}

#side-heading
{
    margin: 70px 0 50px 0;
}

@media(min-width: 750px)
{
    #top-heading
    {
        display: block;
    }
    
    #side-heading
    {
        display: none;
    }
}

@media(min-width: 1000px)
{
    #top-heading
    {
        display: none;
    }
    
    #side-heading
    {
        display: block;
        text-align: left;
        margin: 0 0 50px 0;
    }
}

#about
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin: 100px 0px;
}

@media(min-width: 750px)
{
    #about
    {
        flex-direction: row;
        align-items: center;
    }
}

#about img
{
    width: 100%;
    min-width: 0;
    max-width: 400px;
}

@media(min-width: 750px)
{
    #about-text
    {
        flex-basis: 520px;
        padding: 0 0 0 40px;
    }
}

/* _______________________________________________ SKILLS */

#skills
{
    position: relative;
    padding: 100px 0 120px 0;
    z-index: 1;
}

#skills:before
{
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX( -50%);
    height: 100%;
    width: 100vw;
    padding-bottom: 130px;
    background: var(--bg-light-blue);
    z-index: -1;
}

#skills #heading
{
    margin-bottom: 50px;
}

#skills h2
{
    text-align: center;
    margin-bottom: 10px;
}

#skills p
{
    text-align: center;
}

#skills-grid
{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    box-sizing: border-box;
    margin: auto;
    align-items: center;
    justify-items: center;
    z-index: 1;
    max-width: 600px;
}

@media(min-width: 750px)
{
    #skills-grid
    {
        max-width: 700px;
    }
}

#skills-grid div
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 260px;
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    transition: 0.2s all;
}

#skills-grid div:hover, #skills-grid div:active
{
    background-color: var(--bg-white);
    box-shadow: 0 0 20px #00000010;
}

#skills-grid div p
{
    text-align: center;
    max-width: 180px;
}

#skills-grid #uiux-p
{
    max-width: 220px;
}

@media(min-width: 520px)
{
    #skills-grid
    {
        grid-template-columns: repeat(2, 1fr);
    }
}

#skills-grid div h3
{
    margin-bottom: 8px;
}

#skills-grid img
{
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
}